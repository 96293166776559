<template>
    <header class="header">

        <!-- navbar for pc -->

        <nav class="navbar ">

            <div class="navbar__container">

                <section class="navbar__start">

                    <v-app-bar-nav-icon class="button navbar__button d-flex" @click="toggleSideWindow()"
                    @mouseleave="mouseLeaveHamberger()"></v-app-bar-nav-icon>
                    <!-- <button class="button navbar__button d-flex" @click="toggleSideWindow()"
                        @mouseleave="mouseLeaveHamberger()">

                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
                            <defs:style>
                                .a {
                                fill: #002036;
                                }
                            </defs:style>
                            <path class="a"
                                d="M29.8,80.427H.4a.4.4,0,0,1-.4-.4V78.4A.4.4,0,0,1,.4,78H29.8a.4.4,0,0,1,.4.4v1.618A.4.4,0,0,1,29.8,80.427Zm0,10.787H.4a.4.4,0,0,1-.4-.4V89.191a.4.4,0,0,1,.4-.4H29.8a.4.4,0,0,1,.4.4v1.618A.4.4,0,0,1,29.8,91.214ZM29.8,102H.4a.4.4,0,0,1-.4-.4V99.978a.4.4,0,0,1,.4-.4H29.8a.4.4,0,0,1,.4.4V101.6A.4.4,0,0,1,29.8,102Z"
                                transform="translate(0 -78)" />
                        </svg>
                    </button> -->

                    <div class="logo navbar__logo">
                        <router-link to="/" tag="a" class="link d-flex" exact>
                            <img class="img" :src="currentLogo" alt="">
                        </router-link>
                    </div>


                </section>

                <section class="menu navbar__menu">

                    <ul class="menu__list">
                        <router-link v-if="hasPathAccess('/')" to="/"
                            v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item" :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("Main") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/tourism')" to="/tourism"
                            v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item" :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("Tourism") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/communication')" to="/communication"
                            v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item" :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("Mobile Prepaid") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/payment')" to="/payment"
                            v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item" :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("Payments") }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/gift-card')" to="/gift-card"
                            v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item" :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link space-nowrap">{{ $t("Gift Card")
                                }}</a>

                            </li>

                        </router-link>
                        <router-link v-if="hasPathAccess('/game')" to="/game"
                            v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item" :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("Games") }}</a>

                            </li>

                        </router-link>
                        <!-- <router-link v-if="hasPathAccess('/products')" to="/products"
                                     v-slot="{ href, route, navigate, isActive, isExactActive }">

                            <li class="menu__item"
                                :class="[isExactActive && activeMenuItemClass]">

                                <a :href="href" @click="navigate" class="link menu__link">{{ $t("Products") }}</a>

                            </li>

                        </router-link> -->

                    </ul>

                </section>
            </div>

            <div class="navbar__wrapper">
                <section class='checkout-container cursor-pointer' v-show="cartTotalQuantity">
                    <router-link to="/cart">
                        <img class="" src="/assets/img/shopping-cart-light.svg" alt="">
                        <span>Checkout</span>
                    </router-link>

                    <div class="counter">{{ cartTotalQuantity }}</div>
                </section>

                <!-- contact support button -->
                <v-btn
                  class="ml-2"
                  min-width="0"
                  text
                  @click="openSupportModal"
                >
                    <img src="/assets/img/user-headset-dark.svg" alt="" @click="openSupportModal" />
                </v-btn>

                <!-- change language button -->
                <ChangeLangSelect></ChangeLangSelect>

                <!-- account dropdown -->
                <v-menu bottom left offset-y :nudge-bottom="13" origin="top right" transition="scale-transition">
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn class="mx-2" min-width="0" text v-bind="attrs" v-on="on">
                            <img src="/assets/img/user.svg" alt="">
                        </v-btn>
                    </template>

                    <v-list nav dense flat width="256">
                        <router-link to="/account">
                            <v-list-item dis disabled class="d-flex align-items-center">
                                <v-list-item-avatar>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h6">
                                        {{ user_details && user_details.businessName ? user_details.businessName : '' }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ $t("Customer number") }}: {{ user_details && user_details.business_identifier ?
                                            user_details.business_identifier : '---' }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </router-link>
                        <v-divider class="m-0"></v-divider>
                        <!-- <v-list-item class="d-flex align-items-center" @click="openSupportModal">
                            <v-list-item-icon>
                                <v-icon>mdi-face-agent</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Support
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        <v-list-item @click="handleLogout()">
                            <v-list-item-icon>
                                <v-icon>mdi-logout</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text='$t("Log off")'></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <section v-if="user_balance" class="balance ">

                    <div v-if="balanceCreditBalanceData" class="balance__info">
                        <p class="text balance__caption">
                            {{ balanceCreditBalanceLabelFormatted }}
                        </p>
                        <div class="">
                            <b class="balance__money-amount">{{ balanceCreditBalanceValueFormatted }}</b>
                            <b class="balance__current">{{ $t("NIS") }}</b>
                        </div>
                    </div>

                    <div class="balance__more-info">

                        <ul class="balance__list">

                            <li v-if="balanceCreditBalanceData" class="balance__item">
                                <span class="balance__text">
                                    {{ balanceCreditBalanceLabelFormatted }}
                                </span>
                                <b class="frame-amount">{{ balanceCreditBalanceValueFormatted }}</b>
                            </li>

                            <li v-if="balanceFrameData" class="balance__item">
                                <span class="balance__text">
                                    {{ balanceFrameLabelFormatted }}
                                </span>
                                <b class="used-amount">{{ balanceFrameValueFormatted }}</b>
                            </li>

                            <li v-if="balanceBalanceData" class="balance__item">
                                <span class="balance__text">
                                    {{ balanceBalanceLabelFormatted }}
                                </span>
                                <b class="left-amount">{{ balanceBalanceValueFormatted }}</b>
                            </li>

                            <li v-if="balancePointsBalanceData" class="balance__item">
                                <span class="balance__text">
                                    {{ balancePointsBalanceLabelFormatted }}
                                </span>
                                <b class="points-amount">{{ balancePointsBalanceValueFormatted }}</b>
                            </li>

                        </ul>

                    </div>

                </section>

            </div>

        </nav>

        <!-- navbar for mobile -->

        <nav class="navbar-mobile">

            <section class="navbar-mobile__panel">

                <button class="button navbar-mobile__menu-button">

                </button>

                <div class="navbar-mobile__logo">
                    <router-link :to="{
                        name: 'Dashboard',
                    }" class="link d-flex justify-content-center w-100 h-100">
                        <img class="img" src="/assets/img/logo/main-logo.png" alt="">
                    </router-link>
                </div>
                <div class="d-flex align-items-center">
                    <!-- change language button -->
                    <ChangeLangSelect></ChangeLangSelect>

                    <!-- Support -->
                    <v-btn
                        class="mx-2"
                        min-width="0"
                        text
                        @click="openSupportModal"
                    >
                        <img src="/assets/img/user-headset-dark.svg" alt="" @click="openSupportModal" />
                    </v-btn>

                    <!-- Profile -->
                    <button class="button navbar-mobile__profile-button">
                        <div class="navbar-mobile__user">
                            <img src="/assets/img/user.svg" alt="">
                        </div>
                    </button>
                </div>
                

                <template v-if="cartTotalQuantity != 0">
                    <router-link :to="{ name: 'CartSystem' }" class="cart-icon-block">
                        <img src="/assets/img/icons/shopping-cart-light.svg" alt="">
                        <div class="cart-number df-c">
                            {{ cartTotalQuantity }}
                        </div>
                    </router-link>
                </template>

            </section>

            <section class="profile-mobile navbar-mobile__profile-mobile">

                <div class="profile-mobile__profile">

                    <div class="profile-mobile__icon">
                        <img src="/assets/img/user.svg" alt="">
                    </div>

                    <div>
                        <p class="text profile-mobile__client-name">
                            {{ user_details && user_details.businessName ? user_details.businessName : '' }}
                        </p>
                        <p class="text profile-mobile__client-number">
                            {{ $t("Customer number") }} {{ user_details && user_details.business_identifier ?
                                user_details.business_identifier : '' }}
                        </p>
                    </div>

                </div>

                <ul class="profile-mobile__list">

                    <li v-if="balanceCreditBalanceData" class="profile-mobile__item">
                        <span class="profile-mobile__text">
                            {{ balanceCreditBalanceLabelFormatted }}
                        </span>
                        <b class="frame-amount">{{ balanceCreditBalanceValueFormatted }}</b>
                    </li>
                    <li v-if="balanceFrameData" class="profile-mobile__item">
                        <span class="profile-mobile__text">
                            {{ balanceFrameLabelFormatted }}
                        </span>
                        <b class="used-amount">{{ balanceFrameValueFormatted }}</b>
                    </li>
                    <li v-if="balanceBalanceData" class="profile-mobile__item">
                        <span class="profile-mobile__text">
                            {{ balanceBalanceLabelFormatted }}
                        </span>
                        <b class="left-amount">{{ balanceBalanceValueFormatted }}</b>
                    </li>
                    <li v-if="balancePointsBalanceData" class="profile-mobile__item">
                        <span class="profile-mobile__text">
                            {{ balancePointsBalanceLabelFormatted }}
                        </span>
                        <b class="points-amount">{{ balancePointsBalanceValueFormatted }}</b>
                    </li>
                    <!-- <li class="profile-mobile__item">
                        <a class="link profile-mobile__messege" href="">
                            <span class="profile-mobile__text">
                                {{ $t("Updates and announcements") }}
                            </span>
                            <span class="profile-mobile__messege-amount">2</span>
                        </a>
                    </li> -->
                    <li class="profile-mobile__item">
                        <router-link to="/account">
                            {{ $t("Personal Area") }}
                        </router-link>
                    </li>
                    <li class="profile-mobile__item" @click="openSupportModal">
                        <a class="link">
                            {{ $t("Support") }}
                        </a>
                    </li>
                    <!-- <li v-if="deferredPrompt" class="profile-mobile__item">
                        <a
                            class="link profile__link shortcut-shaniv d-flex align-items-center cursor-pointer add-to-home-screen">
                            <img src="/assets/img/logo/logo-mobile.png" alt="">
                            {{ $t("Create shortcut") }}
                        </a>
                    </li> -->
                    <!-- <li class="profile-mobile__item">
                        <a class="link profile-mobile__messege" href="/cart">
                            <span class="profile-mobile__text">
                                Checkout
                            </span>
                            <span class="profile-mobile__messege-counter"
                                style="background: rgb(71, 232, 183);color: rgb(11, 25, 59);border-radius: 50%;font-size: 14px;max-width: 24px;max-height: 24px;width: 100%;height: auto;position: relative;display: flex;align-items: center;justify-content: center;line-height: 1rem;">{{ cartTotalQuantity }}</span>
                        </a>
                    </li> -->
                    <li class="profile-mobile__item" @click="handleLogout()">
                        <a class="link">
                            {{ $t("Log off") }}
                        </a>
                    </li>

                </ul>

            </section>
        </nav>
        <SupportPopup></SupportPopup>
    </header>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import SupportPopup from "@/components/SupportPopup.vue";
import ChangeLangSelect from '@/components/ChangeLangSelect';

export default {
    name: 'Header',
    props: {
        msg: String
    },
    components: {
        SupportPopup,
        ChangeLangSelect
    },
    data: () => {
        return {
            activeMenuItemClass: 'menu__item_active',
        }
    },
    methods: {
        ...mapActions('account', ['getUserDetails', 'logout']),
        formatNumber(number) {
            let numberFormatObject = new Intl.NumberFormat('en-US')
            return numberFormatObject.format(number)
        },
        toTitleCase(str) {
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        toggleSideWindow() {
            setTimeout(() => {
                if (document.getElementsByClassName('aside')[0].classList.contains("aside_hidden")) {
                    localStorage.setItem("isAside", false);
                    localStorage.setItem("isOpenAside", false);
                    document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
                    document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
                } else {
                    localStorage.setItem("isOpenAside", true);
                    if (this.$i18n.locale == "en") {
                        document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
                        document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = '250px';
                    } else {
                        document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
                        document.getElementsByClassName('blank-aside-js')[0].style.marginRight = '250px';
                    }
                }
            }, 100);
        },
        mouseLeaveHamberger() {
            setTimeout(() => {
                if (localStorage.getItem("isAside") == "false") {
                    if (localStorage.getItem("isPin") == "false" && !document.getElementsByClassName('aside')[0].classList.contains("aside_hidden")) {
                        document.getElementById('aside_scroll').classList.add("aside_hidden");
                        document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
                        document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
                    }
                }
            }, 3000);
        },
        hideSideMenu() {
            if (document.getElementById('aside_scroll').classList.contains("aside_active")) {
                document.getElementById('aside_scroll').classList.remove("aside_active");
                document.getElementsByClassName('navbar-mobile__menu-button')[0].classList.remove("navbar-mobile__menu-button_active");
            }
        },
        openSupportModal() {
            this.$modal.show('supportPopup');
        },
        async handleLogout() {
            await this.logout();
            this.$router.push('/login');
        }
    },
    computed: {
        ...mapState({
            user_details: state => state.account.user_details && state.account.user_details.hasOwnProperty('details') ? state.account.user_details.details : null,
            user_balance: state => state.account.user_details && state.account.user_details.hasOwnProperty('balance') ? state.account.user_details.balance : null,
            productsCounter: (state) => state.cartSystem.productsCounter,
            packageCounter: (state) => state.cartSystem.packageCounter,
            deferredPrompt: state => state.add_to_home_screen.deferredPrompt
        }),
        ...mapGetters('permission', {
            hasPathAccess: 'hasPathAccess'
        }),
        ...mapGetters('cartSystem', {
            cartTotalQuantity: 'cartTotalQuantity'
        }),
        balanceFrameData() {
            return this.user_balance && Array.isArray(this.user_balance) && 0 in this.user_balance ? this.user_balance[0] : null;
        },
        balanceBalanceData() {
            return this.user_balance && Array.isArray(this.user_balance) && 1 in this.user_balance ? this.user_balance[1] : null;
        },
        balanceCreditBalanceData() {
            return this.user_balance && Array.isArray(this.user_balance) && 2 in this.user_balance ? this.user_balance[2] : null;
        },
        balancePointsBalanceData() {
            return this.user_balance && Array.isArray(this.user_balance) && 3 in this.user_balance ? this.user_balance[3] : null;
        },
        balanceFrameValueFormatted() {
            return this.balanceFrameData ? this.formatNumber(this.balanceFrameData.value) : ''
        },
        balanceBalanceValueFormatted() {
            return this.balanceBalanceData ? this.formatNumber(this.balanceBalanceData.value) : ''
        },
        balanceCreditBalanceValueFormatted() {
            return this.balanceCreditBalanceData ? this.formatNumber(this.balanceCreditBalanceData.value) : ''
        },
        balancePointsBalanceValueFormatted() {
            return this.balancePointsBalanceData ? this.formatNumber(this.balancePointsBalanceData.value) : ''
        },
        balanceFrameLabelFormatted() {
            return this.balanceFrameData ? this.toTitleCase(this.balanceFrameData.strRepr) : ''
        },
        balanceBalanceLabelFormatted() {
            return this.balanceBalanceData ? this.toTitleCase(this.balanceBalanceData.strRepr) : ''
        },
        balanceCreditBalanceLabelFormatted() {
            return this.balanceCreditBalanceData ? this.toTitleCase(this.balanceCreditBalanceData.strRepr) : ''
        },
        balancePointsBalanceLabelFormatted() {
            return this.balancePointsBalanceData ? this.toTitleCase(this.balancePointsBalanceData.strRepr) : ''
        },
        counter() {
            return this.productsCounter + this.packageCounter;
        }
    },
    created() {
        // if (this.user_details && Object.keys(this.user_details).length)
            this.getUserDetails();
    },
    watch: {
        '$route'() {
            this.hideSideMenu();
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    z-index: 102;
}

.navbar {
    font-size: 16px;
    height: 60px;
    flex-wrap: nowrap;

    .profile__item {
        padding: 5px 14px;
    }
}

.navbar__logo {
    height: auto;
    width: 124px;
}

.navbar__start {
    width: 180px;
}

.navbar__menu {
    max-width: unset;
    margin: 0 20px;
}

.navbar-mobile__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 36px;
    img {
        object-fit: contain;
    }
}

.checkout-container {
    margin-left: 1rem;
    color: white;
    position: relative;

    a {
        background: #234395;
        padding: 0.4rem 1.2rem;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        color: white;
        text-decoration: unset;

        span {
            margin-right: 8px;
        }
    }

    .counter {
        background: #47E8B7;
        color: #0B193B;
        border-radius: 50%;
        position: absolute;
        max-width: 32px;
        max-height: 32px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 100%;
        transform: translate(-50%, -20%);
    }
}

.ltr-type {
    .balance__info {
        border-right: unset;
        padding-right: unset;
        margin-right: unset;
        border-left: 2px solid #002036;
        padding-left: 15px;
        margin-left: 25px;
    }

    .balance__more-info {
        left: -120px;

        &::before {
            left: unset;
            right: 60px;
        }
    }

    .checkout-container {
        margin-right: 1rem;

        a span {
            margin-left: 8px;
        }

        .counter {
            left: 0;
        }
    }

    .cart-icon-block {
        left: unset;
        right: 100px;
    }
}

.cart-icon-block {
    position: absolute;
    left: 100px;
    top: 15px;

    img {
        height: 32px;
    }

    .cart-number {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 13px;
        background-color: #47E8B7;
        top: -5px;
        right: -10px;
    }
}

.shortcut-shaniv {
    img {
        height: 30px;
        margin-left: 5px;
    }
}

.ltr-type {
    .shortcut-shaniv {
        img {
            margin-right: 5px;
            margin-left: unset;
        }
    }
}
</style>