<template>
  <modal
    :width="800"
    :adaptive="true"
    class="supportPopup CEModal modal-scroll-bar"
    name="supportPopup"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <h4 class="text-center fs20 mb-5 mt-5 font-weight-bold">
      {{ $t('Support') }}
    </h4>
    <p class="text-center">
      {{ $t("Answer A to T between the hours") }}: 09:00-17:00
    </p>
    <div class="d-flex support-contents mt-10">
      <div class="support-item text-center">
        <img src="/assets/img/icons/desktop-light.svg" alt="">
        <p>{{ $t("For support by screen sharing") }}</p>
        <div class="d-flex justify-content-center mt-5">
          <a 
            href="https://anydesk.com/en/downloads/mac-os?dv=mac_dmg"
            class="text-center p-2"
            target="_blank"
            rel="noopener noreferrer"
          > 
            <img src="/assets/img/os/apple.png" class="os-img" alt="">
            <h6>{{ $t("Macintosh") }}</h6>
          </a>
          <a 
            href="https://anydesk.com/en/downloads/linux?dv=deb_64"
            class="text-center p-2"
            target="_blank"
            rel="noopener noreferrer"
          > 
            <img src="/assets/img/os/ubuntu.png" class="os-img" alt="">
            <h6>{{ $t("Linux") }}</h6>
          </a>
          <a 
            href="https://play.google.com/store/apps/details?id=com.anydesk.anydeskandroid"
            class="text-center p-2"
            target="_blank"
            rel="noopener noreferrer"
          > 
            <img src="/assets/img/os/android.png" class="os-img" alt="">
            <h6>{{ $t("Android") }}</h6>
          </a>
          <a 
            href="https://anydesk.com/en/downloads/windows?dv=win_exe"
            class="text-center p-2"
            target="_blank"
            rel="noopener noreferrer"
          > 
            <img src="/assets/img/os/windows.png" class="os-img" alt="">
            <h6>{{ $t("Windows") }}</h6>
          </a>
        </div>
      </div>
      <div class="support-item text-center chat-item">
        <img 
          src="/assets/img/icons/chat_icon.svg" 
          alt=""
          class="chat-icon"
        >
        <p>{{ $t("To chat with a representative click on the support icon in the lower corner") }}</p>
      </div>
      <div class="support-item text-center">
        <img 
          src="/assets/img/icons/user-headset-light-primary.svg" 
          alt=""
          class="phone-support-img"
        >
        <p>{{ $t("For support with a representative on the phone") }}</p>
        <h3 class="color-blue6">054-3095860</h3>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'supportPopup',
  data() {
    return {

    }
  },
  methods: {
    closeModal() {
      this.$modal.hide("supportPopup");
    },
  }
}
</script>

<style lang="scss">
.supportPopup {
  .vm--modal {
    padding: 10px;
  }
}
</style>
<style lang="scss" scoped>
.supportPopup {
  font-size: 14px;
  .support-contents {
    @media (max-width: 700px) {
      flex-wrap: wrap;
    }
    .support-item {
      width: 100%;
      padding: 10px 20px;
      .os-img {
        width: 30px;
        height: 30px;
      }
      .phone-support-img {
        height: 40px;
      }
      .chat-icon {
        height: 60px;
      }
    }
    .chat-item {
      border-left: 1px solid #dedede;
      border-right: 1px solid #dedede;
      @media (max-width: 700px) {
        border-left: none;
        border-right: none;
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
      }
    }
  }
}
</style>