<template>
  <aside class="cus-asid aside" id="aside_scroll" @mouseleave="mouseLeave" @mouseenter="mouseEnter" :class = "(isOpenAside=='true')?'':'aside_hidden'">
    <img src="/assets/img/icons/push-pin-svgrepo-com.svg" alt="" id="pin_icon" class="pin-icon" @click="changePin(false)" v-if="isPin">
    <img src="/assets/img/icons/push-unpin-svgrepo-com.svg" alt="" class="pin-icon" @click="changePin(true)" v-else >
    <nav class="aside__navigation">
      <ul class="aside__list">
        <template v-for="(menu, i) in allowedMenuItems">
          <li class="aside__item"  :key="i" v-if="!menu.meta.subAdmins">
            <router-link :to="menu.page" v-slot="{ href, route, navigate, isActive, isExactActive }">
              <a
                :href="href"
                @click="navigate"
                :class="[isExactActive && activeAsideItemClass, 'aside__link-'+ menu.icon]"
                class="link aside__link"
              >{{ $t(menu.translate) }}</a>
            </router-link>
          </li>
          <li class="aside__item"  :class="['aside__item-' + menu.icon, ]" :key="i" v-else>
            <a
              href="#"
              class="link aside__link aside__link-plus"
              :class="['aside__link-' + menu.icon]"
              :ref="'root' + menu.root"
            >{{ $t(menu.translate) }}</a>
            <ul 
              class="aside__second-lvl"
              :class="'aside__second-lvl' + menu.icon" 
              :ref="'root_ul' + menu.root">
              <template v-for="(smenu, j) in menu.submenu">
                <li class="aside__item-lvl aside__item-second" 
                    :key="'s' + j"
                    :class="smenu.meta.isLinked ? '' : 'unlinked-item'"
                    v-if="!smenu.meta.subAdmins">
                  <router-link
                    :to="smenu.page"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                  >
                    <a
                      :href="href"
                      @click="navigate"
                      :class="[isExactActive && activeAsideItemClass]"
                      class="link aside__link-lvl d-flex align-items-center"
                      v-if="smenu.meta.isIcon"
                    >
                      <img :src="smenu.meta.iconSrc" alt="" class="mx-5">
                      {{ $t(smenu.translate) }}
                    </a>
                    <a
                      :href="href"
                      @click="navigate"
                      :class="[isExactActive && activeAsideItemClass]"
                      class="link aside__link-lvl"
                      v-else
                    >
                      {{ $t(smenu.translate) }}
                    </a>
                  </router-link>
                </li>
                <li class="aside__item-lvl aside__item-second" :key="'s' + j" v-else>
                  <a
                    href
                    class="link aside__link-lvl aside__link-js aside__link-plus"
                    :ref="'parent' + menu.root + '_' + smenu.root"
                  >{{ $t(smenu.translate) }}</a>

                  <ul class="aside__third-lvl" :ref="'parent_ul' + menu.root + '_' + smenu.root">
                    <li 
                      class="aside__item-third"
                      :class="ssmenu.meta.isLinked ? '' : 'unlinked-item'"
                      v-for="(ssmenu, k) in smenu.submenu"
                      :key="'ss' + k">
                      <router-link
                        :to="ssmenu.page"
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                      >
                        <a
                          :href="href"
                          @click="navigate"
                          :class="[isExactActive && activeAsideItemClass]"
                          class="link aside__link-lvl"
                        >{{ $t(ssmenu.translate) }}</a>
                      </router-link>
                    </li>
                  </ul>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </nav>
  </aside>
</template>

<script>
import menuConfig from "../common/menu.config.json";
import { mapGetters } from "vuex";

export default {
  name: "Aside",
  props: {
    msg: String
  },
  data: () => {
    return {
      activeAsideItemClass: "aside__link_active",
      settings: {
        suppressScrollX: true,
        maxScrollbarLength: 300
      },
      isPin: true
    };
  },
  computed: {
    ...mapGetters('permission', {
      hasPathAccess: 'hasPathAccess'
    }),
    menuItems() {
      return menuConfig.aside.items;
    },
    // todo: improve the logic of allowedMenuItems
    allowedMenuItems() {
      let allowedAsideItems = [];
      if (this.menuItems && Array.isArray(this.menuItems)) {
        for (let asideItem of this.menuItems) {
          if (!asideItem.meta.subAdmins && 'page' in asideItem) {
            if (this.hasPathAccess(asideItem.page)) {
              allowedAsideItems.push(asideItem);
            }
          } else if (asideItem.meta.subAdmins && asideItem.submenu) {
            let asideItemTemp = JSON.parse(JSON.stringify(asideItem));
            asideItemTemp.submenu = [];
            for (let asideSubItem of asideItem.submenu) {
              if (!asideSubItem.meta.subAdmins && 'page' in asideSubItem) {
                if (this.hasPathAccess(asideSubItem.page)) {
                  asideItemTemp.submenu.push(asideSubItem);
                }
              } else if (asideSubItem.meta.subAdmins && asideSubItem.submenu) {
                let asideSubItemTemp = JSON.parse(JSON.stringify(asideSubItem));
                asideSubItemTemp.submenu = [];
                for (let asideSubSubItem of asideSubItem.submenu) {
                  if ('page' in asideSubSubItem) {
                    if (this.hasPathAccess(asideSubSubItem.page)) {
                      asideSubItemTemp.submenu.push(asideSubSubItem);
                    }
                  }
                }
                if (asideSubItemTemp.submenu.length) {
                  asideItemTemp.submenu.push(asideSubItemTemp);
                }
              }
            }
            if (asideItemTemp.submenu.length) {
              allowedAsideItems.push(asideItemTemp);
            }
          }
        }
      }
      return allowedAsideItems;
    },
  },
  methods: {
    mouseLeave() {
      if (this.isPin == false) {
        document.getElementById('aside_scroll').classList.add("aside_hidden");
        document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
        document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
        localStorage.setItem("isAside", false);
        localStorage.setItem("isOpenAside", false);
      }
    },
    mouseEnter() {
      localStorage.setItem("isAside", true);
    },
    changePin(state) {
      localStorage.setItem("isPin", state);
      this.isPin = state;
    },
    onResize(event) {
      if (window.innerWidth < 1365) {
        this.isPin = true;
        if (document.getElementById("pin_icon")) {
          document.getElementById("pin_icon").style.visibility = "hidden";
        }
        document.getElementById('aside_scroll').classList.remove("aside_hidden");
      } else {
        document.getElementById("pin_icon").style.visibility = "visible";
      }
    }
  },
  mounted() {
    localStorage.setItem("isPin", this.isPin);
    const root_id = this.$route.meta.root;
    if (root_id && root_id != 0) {
      this.$refs["root" + root_id][0].classList.add("aside__link-plus_active");
      this.$refs["root_ul" + root_id][0].style.display = "block";
      if(this.$route.meta.parent && this.$route.meta.parent != 0) {
        let p_id = this.$route.meta.parent;
        this.$refs["parent" + root_id + "_" + p_id][0].classList.add("aside__link-plus_active");
        this.$refs["parent_ul" + root_id + "_" + p_id][0].style.display = "block";
      }
    }
    window.addEventListener('resize', this.onResize)
    if (window.innerWidth < 1365) {
      document.getElementById("pin_icon").style.display = "none";
    }

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
};
</script>
<style lang="scss" scoped>
.aside__navigation {
  padding-bottom: 60px;
}
.pin-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 10px;
  z-index: 9;
}

.aside__link {
  font-size: 18px;
}
.aside__item {
  margin-bottom: 36px;
}

.aside {
  width: 250px;
  border-left: 2px solid #dfe0e3 !important;
  position: relative;
  &_hidden {
    margin-right: -250px;
  }

  &__list {
    width: 250px;
  }

  .aside__link-lvl {
    padding-right: 10px;
    padding-left: 0;
  }

  .aside__item-third {
    &:first-child {
      border-top: 2px solid #345972;
    }
    &:last-child {
      border-bottom: unset;
    }
    border-bottom: 2px solid #345972;
    .aside__link-lvl {
      padding-right: 25px;
      padding-left: 0;
    }
  }

  .aside_active {
    right: 0 !important;
  }
  .aside__link-sastech {
    margin-bottom: 15px;
  }
  .aside__link-sastech::before {
    content: "";
    width: 27px;
    height: 28px;
    position: absolute;
    right: -45px;
    top: 0;
    background: url(/assets/img/shopping-bag-light.svg) no-repeat;
  }
  .aside__link-game::before {
    content: "";
    width: 27px;
    height: 28px;
    position: absolute;
    right: -45px;
    top: 0;
    background: url(/assets/img/icons/games.svg) no-repeat;
  }
  .aside__link-user {
    margin-bottom: 15px;
  }

  .aside__link-plus::after {
    left: 20px;
  }
  .aside__second-lvl {
    padding: 0 15px;
    .aside__link-plus::after {
      left: 5px;
    }
  }
  .aside__item-second {
    border-top: unset;
    border-bottom: 2px solid #345972;
    &:first-child {
      border-bottom: 2px solid #345972;
    }
  }
}

.cus-asid {
  height: calc(100% - 25px);
  position: fixed;
  z-index: 101;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 30px;
}

#aside_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#aside_scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#aside_scroll::-webkit-scrollbar-thumb {
  background-color: #00203699;
}

#aside_scroll {
  scrollbar-width: thin;
  scrollbar-color: #586B78 transparent;
}
#aside_scroll:-webkit-scrollbar {
  width: 11px;
}
#aside_scroll:-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
#aside_scroll:-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 6px;
  border: 3px solid transparent;
}

.ltr-type {
  .aside__link-home::before,
  .aside__link-plane::before,
  .aside__link-mobile::before,
  .aside__link-money::before,
  .aside__link-gift::before,
  .aside__link-file::before {
    left: -45px;
  }
  .aside__link-plus::after {
    right: 20px;
    left: unset;
  }

   .aside__second-lvl {
    padding: 0 15px;
    .aside__link-plus::after {
      right: 5px;
      left: unset;
    }
  }
  .aside__link-user::before {
    left: -45px;
  }
  .aside__second-lvl {
    margin-left: -65px;
    margin-right: 0;
  }
  .aside {
    border-left: 0 !important;
    border-right: 2px solid #dfe0e3;
    left: 0;
  }
  .aside__list {
    padding-right: 0;
    padding-left: 65px;
  }
  .aside_active {
    left: 0 !important;
  }
  .aside__link-lvl {
    padding-left: 10px;
    padding-right: 0;
  }
  .pin-icon {
    left: unset;
    right: 20px;
  }
  .aside__item-third {
    .aside__link-lvl {
      padding-left: 25px;
      padding-right: 0;
    }
  }
  .aside__link-sastech::before,
  .aside__link-game::before {
    left: -45px;
  } 
}
@media screen and (max-width: 767px) {
  .aside {
    width: 100vw;
    right: -100vw;
  }
  .aside__list {
    width: 100%;
  }
  .aside_hidden {
    margin-right: 0;
  }
}
@media screen and (max-width: 1365px) {
  .aside {
    right: -250px;
  }
  .aside_active {
    right: 0 !important;
  }
  .cus-asid {
    padding-bottom: 30px;
  }
  .ltr-type .aside {
    left: -250px;
    right: unset;
  }
  .ltr-type .aside_active {
    left: 0;
    right: unset;
  }
}
</style>

<style lang="scss" scoped>
.unlinked-item {
  display: none;
  a {
    color: #C92D4C !important;
  }
}
</style>