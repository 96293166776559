<template>
    <v-menu
        bottom
        left
        offset-y
        :nudge-bottom="13"
        origin="top right"
        transition="scale-transition"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ attrs, on }">
            <v-btn
                class="ml-2"
                min-width="0"
                text
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-web</v-icon>
            </v-btn>
        </template>
        <v-list width="200">
            <v-subheader>{{ $t("Language") }}</v-subheader>
            <v-list-item class="d-flex align-items-center">
                <v-select
                    :items="language.options"
                    v-model="language.selected"
                    @input="changeLang"
                    :hide-details="true"
                    :single-line="true"
                    dense
                    solo
                ></v-select>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { mapActions } from 'vuex';
import i18nService from "@/common/i18n.service.js";
export default {
    name: "ChangeLangSelect",
    props: {
        isLoginPage: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            activeMenuItemClass: 'menu__item_active',
            language: {
                options: [
                    {
                        value: 'he',
                        text: 'עברית'
                    },
                    {
                        value: 'ar',
                        text: 'العربية'
                    },
                    {
                        value: 'en',
                        text: 'English'
                    },
                ],
                selected: 'he'
            }
        }
    },
    created() {
        this.setDefaultLang();
    },
    methods: {
        ...mapActions("language", {
            changeCurrentLang: "changeCurrentLang",
        }),
        changeLang(langId) {
            const that = this;
            if (this.isLoginPage) {
                this.setNewLang(langId)
            } else {
                this.changeCurrentLang({
                    lang: langId
                }).then((res) => {
                    if (res == "success") {
                        that.setNewLang(langId)
                    } else {
                        that.setDefaultLang();
                    }
                });
            }
        },
        setNewLang(langId) {
            i18nService.setActiveLanguage(langId);
            this.$vuetify.lang.current = langId
            if (langId == "en") {
                this.$vuetify.rtl = false
                document.getElementsByTagName("body")[0].classList.remove("rtl-type");
                document.getElementsByTagName("body")[0].classList.add("ltr-type");
            } else {
                this.$vuetify.rtl = true
                document.getElementsByTagName("body")[0].classList.remove("ltr-type");
                document.getElementsByTagName("body")[0].classList.add("rtl-type");
            }
            window.location.reload();
        },
        setDefaultLang() {
            const current_langId = i18nService.getActiveLanguage();
            this.language.selected = this.language.options.find( (el) => {
                return el.value == current_langId
            }).value; 
        },
    },
}
</script>